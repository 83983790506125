:root {
  --space: 0rem;
  --sidebar-width: 250px;
  --header-height: 40px;
}

@media screen and (min-width: 960px) {
  html {
    margin-left: calc(100vw - 100%);
    margin-right: 0;
  }
}

/* Animations */
@keyframes pulse {
  0% {
    opacity: 0;
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 0.95;
  }
}

@keyframes activate-selection {
  from {
    background-color: #ffffff;
    color: #ffffff;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  --main-spacing: 15px 5px 10px 5px;
  --header-footer-spacing: 10px 5px;
}

.br-blur {
  opacity: 0.5;
}

/** start comments **/
div.comment-sidebar > div > .comments > .comment,
div.comment-sidebar > .comments > .comment,
div.comment-sidebar > .header {
  padding: 10px !important;
}
.comment.active {
  padding: 10px;
}
.inline-comment-block {
  border-radius: 2px;
}
.reaction-selection-comment {
  cursor: pointer;
  background-color: #c0c0c0;
  color: black;
}
.reaction-selection-active {
  background-color: #f0a039;
  cursor: pointer;
}
.comment-element-active {
  border-radius: 10px;
  padding: 10px !important;
  margin-right: 20px !important;
}
.comment-element {
  border-radius: 10px;
  padding: 10px !important;
  margin-right: 20px !important;
}
/** end comments **/

/** reactions start **/
.reactionIcon {
  font-size: 1.6em;
  color: black;
}
.reactionIcon-inline {
  font-size: 0.6em !important;
  color: rgb(255, 0, 0);
  position: absolute;
  margin-top: -8px;
  margin-left: -6px;
  z-index: 1;
}
.reaction-heart {
  color: rgb(255, 0, 0);
}
.reaction-active {
  border-width: 1px;
  border-style: dashed;
  cursor: pointer;
  color: black !important;
  animation-name: activate-selection;
  animation-duration: 1000ms;
}

.reaction-selection-like {
  background-color: #98fb98;
  color: black;
  border-color: #259125;
  cursor: pointer;
}
.reaction-selection-love {
  background-color: #f6adc6;
  color: black;
  border-color: #df155c;
  cursor: pointer;
}
.reaction-selection-laugh {
  background-color: #ffff00;
  color: black;
  border-color: #b8b800;
  cursor: pointer;
}
.reaction-selection-surprise {
  background-color: #40e0d0;
  color: black;
  border-color: #268f84;
  cursor: pointer;
}
.reaction-selection-sad {
  background-color: #87ceeb;
  color: black;
  border-color: #528aa0;
  cursor: pointer;
}
.reaction-selection-angry {
  background-color: #ff6060;
  color: black;
  border-color: #9b2222;
  cursor: pointer;
}
/** reactions end **/

/** posts start **/
.center-cropped {
  height: auto;
  width: 200px;
  background-position: center;
}
.drag * {
  pointer-events: none;
}
.dropzone {
  pointer-events: auto !important;
}
/** posts end **/

/** payments start **/
/* .product-card-feature {
  font-size: 1.1em!important;
}
.product-content {
  min-height: 100%;
}
.product-content-inside {
  padding: 20px;
  padding-bottom: 50px;
}
.product-tos-message {
  color: grey;
  font-size: 1.1em;
  font-style: italic;
}
.product-card-tos-message {
  color: grey;
  font-size: 0.8em!important;
  font-style: italic;
}
.product-card-price {
  margin-top: 1em!important;
  font-size: 2.3em!important;
}
.product-buy-button {
  margin-top: 3em!important;
}
.product-discount-label {
  color: grey;
  font-weight: lighter;
  position: absolute;
  left: 52%;
  margin-top: 1em;
} */
/** payments end **/
.footer {
  margin-top: 0px;
  background-color: white;
}

.book-item-right {
  padding-bottom: -100;
}
.mymanuscripts-analytics-header-small {
  font-size: 1em !important;
  font-weight: bold;
}
.mymanuscripts-analytics-header-large {
  font-size: 1.5em !important;
  color: #5e5e5e !important;
  font-weight: bold;
}
.mymanuscripts-analytics-value {
  font-size: 3em !important;
  color: #00b5ad !important;
  /*  bottom: -50px!important; */
}
.mymanuscripts-analytics-top-segment {
  position: relative !important;
  height: 8em !important;
}

.inlineCommentsList {
  position: fixed !important;
  width: 100% !important;
  /* max-height: 33% !important; */
  left: 0 !important;
  bottom: 0px !important;
  z-index: 999 !important;
}

/* Start NPS */
.text.container.nps-container {
  max-width: 500 !important;
}
/* End NPS */

.show-scroll-bar-on-hover {
  overflow-y: hidden;
}

.show-scroll-bar-on-hover:hover {
  overflow-y: scroll;
}

.reader-progress-bar {
  height: 1em;
  margin: 0 !important;
}

.browse-link-item {
  color: #575757 !important;
}

.browse-link-item:hover {
  color: #3366bb !important;
}

.cover-image:hover {
  box-shadow: 0px 0px 20px -9px rgba(0, 0, 0, 1);
}

.browse-responsive-container {
  position: fixed !important;
  display: block;
  top: 40px; /* to accomodate for header context bar*/
  left: 0;
  width: 100vw;
  max-height: 95vh;
  padding-bottom: 12vh;
  padding-top: 10px;
  overflow: hidden;
  overflow-y: scroll;
  background-color: white;
  z-index: 100;
}

/* Editable */
.editable:hover,
.editable:hover * {
  opacity: 1 !important;
  cursor: pointer;
}
