/** Paragraphs should have 50% spacing between them **/
.br-cp {
  margin-bottom: 1em;
}

.DraftEditor-root {
  line-height: 1.4285em;
  font-size: 1em;
  word-wrap: break-word;
  hyphens: auto;
}

.br-manuscript-style-book {
  font-family: Georgia;
}

.br-manuscript-style-screenplay {
  font-family: monospace, 'Courier New', Courier;
}

/** draft js customToolBar start **/
/* .draftJsToolbar__toolbar__dNtBH:after {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;

  border-top: 4px solid #000000;
}

.draftJsToolbar__toolbar__dNtBH {
  left: 50%;
  -webkit-transform: translate(-50%) scale(0);
          transform: translate(-50%) scale(0);
  position: absolute;
  border: 0px solid transparent;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgba(220,220,220,1);
  z-index: 2;
  box-sizing: border-box;
} */
/** draft js customToolBar end **/

/** draft js customToolBar start **/
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 200%;
  left: -50%;
  /* margin-left: -45px; */
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.draftJsToolbar__toolbar__dNtBH:after {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;

  border-top: 4px solid #000000;
}

.draftJsToolbar__toolbar__dNtBH {
  left: 50%;
  -webkit-transform: translate(-50%) scale(0);
  transform: translate(-50%) scale(0);
  position: absolute;
  border: 0px solid transparent;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgba(220, 220, 220, 1);
  z-index: 2;
  box-sizing: border-box;
}
/** draft js customToolBar end **/
