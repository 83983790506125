/* surveys */
div.text-line {
  line-height: 2;
  border-bottom: solid #dddddd 1px;
}

div.text-line-active {
  line-height: 2;
  border-bottom: solid #f0a039 2px;
}

.question-box {
  font-size: 1.2em !important;
}

.question {
  font-size: 1.5em;
}

.question-description {
  color: grey;
  font-size: 1em;
  line-height: 2;
}

.answer textarea {
  font-size: 0.8em !important;
  line-height: 2 !important;
  vertical-align: bottom !important;
  padding-left: 0 !important;
  padding-bottom: 0 !important;
  border-top: hidden !important;
  border-left: hidden !important;
  border-right: hidden !important;
  border-radius: 0px !important;
  overflow: hidden !important;
  width: 100% !important;
}

.answer {
  font-size: 1em;
  line-height: 2;
  border-bottom: dotted #000000 1px;
}

.answer textarea:focus {
  border-bottom: solid #f0a039 2px !important;
}
/* surveys */

.disabled-link {
  pointer-events: none;
  color: #d3d3d3;
}

.header-link:hover {
  cursor: pointer;
  text-decoration: underline;
}
.label-link {
  color: #575757 !important;
  padding-left: 1em;
  padding-right: 1em;
  cursor: pointer;
}
.label-link:hover {
  color: #3366bb !important;
}

.browse-link-item {
  color: #575757 !important;
}

div.text-line {
  line-height: 2;
  border-bottom: solid #dddddd 1px;
}

div.text-line-active {
  line-height: 2;
  border-bottom: solid #f0a039 2px;
}

textarea {
  background: transparent;
  border: none;
  width: 100%;
  resize: none;
  height: 100%;
  outline: none;
  font-size: 0.9em;
  color: #717171;
}

.cover-thumbnail {
  position: relative;
  display: inline-block;
}

.cover-caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #2f4f4f;
  font-weight: bold;
}

.cover-caption .title {
  font-size: 2em;
}
.cover-caption .author-name {
  font-size: 1em;
}
.notification-dropdown-item-read {
  /* background-color: #D3D3D3!important; */
  color: #96979a !important;
  /* font-style: italic!important; */
}
.notification-dropdown-item {
  border-bottom: 5px solid red !important;
  border-bottom: 2px !important;
  border-color: black !important;
  border-width: medium !important;
}
.dot {
  height: 10px;
  width: 10px;
  background-color: #0078ff;
  border-radius: 50%;
  display: inline-block;
}

/** prevent apple native context menu from appearing */
.br-text {
  user-select: text; /* Standard syntax */
  -webkit-user-select: text; /* Safari/Chrome */
  -webkit-touch-callout: none; /* Disable native toolbar */
  -webkit-tap-highlight-color: transparent; /* Remove tap highlight */
}
