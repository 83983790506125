.scrollable-container {
  overflow: hidden;
}
.scrollable-container:hover {
  overflow-y: scroll;
}

.inputContainer {
  border-right-style: solid!important;
  display: flex!important;
  flex-direction: row!important;
  justify-content: space-between!important;
  border-top-style: solid;
  border-width: thin;
  border-color: #E8E8E8;
  /* padding-top: 10px!important; */
}

.conversation-input-textarea {
  margin-top: 14!important;
  margin-left: 14;
}

.chat-message {
  /* flex-direction: row; */
  /* height: 3em; */
  margin: 0.4em;
}

.chat-message-box {
  /* flex-direction: row;
  display: inline-block!important;
  resize: vertical; */
  flex-direction: row;
  justify-content: flex-start;
  display: grid;
}

.chat-message-box-isCurrentUser {
  flex-direction: row;
  justify-content: flex-end;
  display: grid;
  /* resize: horizontal; */
}

.chat-message-date-header {
  text-align: center;
  color: grey;
  font-weight: bold;
}

.chat-message-content {
  border-radius: 10px;
  padding: 0.9em;
  margin-left: 0.5em;
  margin-right: 0.5em;
  display:inline-grid;
  flex-direction: column;
  background-color: #f0f0f0;
}

.chat-message-content-isCurrentUser {
  border-radius: 10px;
  padding: 0.9em;
  margin-left: 0.5em;
  margin-right: 0.5em;
  display:inline-grid;
  background-color: #0078FF;
}
.chat-message-content-text {
  color: black;
  white-space: pre-wrap;
}
.chat-message-content-text a.linkified {
  color: #51A0D5;
  text-decoration: underline;
}
.chat-message-content-text-date {
  color: black;
  font-size: 0.8em;
  text-align: right;
}
.chat-message-content-text-isCurrentUser {
  white-space: pre-wrap;
  color: white;
}
.chat-message-content-text-isCurrentUser a.linkified {
  color: white;
  text-decoration: underline;
}
.chat-message-content-text-date-isCurrentUser {
  color: white;
  font-size: 0.8em;
  text-align: right;
}

.typing-dot {
  height: 5px;
  width: 5px;
  border-radius: 100%;
  margin-left: 4px;
  display: inline-block;
  background-color: #5C5E69;
  animation: 1.2s typing-dot ease-in-out infinite;
}
.typing-dot:nth-of-type(2) {
  animation-delay: 0.15s;
}
.typing-dot:nth-of-type(3) {
  animation-delay: 0.25s;
}
@keyframes typing-dot {
  15% {
    transform: translateY(-35%);
    opacity: 0.5;
  }
  30% {
    transform: translateY(0%);
    opacity: 1;
  }
}
